/** @jsxImportSource theme-ui */
import React from 'react'
import { useAnalytics } from '@chordcommerce/react-autonomy'
import Link, { LinkProps } from '~/components/Generic/Link'
import { serializeProduct } from '~/utils/chord/serialize'
import type {
  IProduct,
  ICatalogProduct,
  IRelatedProductProduct,
} from 'graphql-cms/types'

type Props = Omit<LinkProps, 'href' | 'onClick'> & {
  product: IProduct | ICatalogProduct | IRelatedProductProduct
}

/**
 * Creates a link to a product with Chord tracking attached.
 */
const ProductLink: React.FC<React.PropsWithChildren<Props>> = ({
  product,
  children,
  ...props
}) => {
  const serializedProduct = serializeProduct(product)
  const { trackProductClicked } = useAnalytics()
  const pathPrefix = serializedProduct.variant.sku?.includes('|')
    ? 'bundles'
    : 'products'
  const url = `/${pathPrefix}/${product?.slug}/`

  return (
    <Link
      href={url}
      onClick={() => trackProductClicked({ product: serializedProduct })}
      {...props}
    >
      {children}
    </Link>
  )
}

export default ProductLink
