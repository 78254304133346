/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import { Flex, ThemeUICSSObject } from 'theme-ui'
import ProductLink from '~/components/Product/Link'
import styles from './styles.module.css'

import type {
  IProduct,
  IRelatedProductProduct,
  ICatalogProduct,
} from 'graphql-cms/types'
import InternalProductCard from './Components/internalProductCard'
import { ISales } from 'basta-ai/types'
import { useTranslate } from '~/hooks'
import theme from '~/theme'

interface Props {
  product: IProduct | ICatalogProduct | IRelatedProductProduct
  sales?: ISales[]
  maxWidth?: ThemeUICSSObject
  maxHeight?: string
  viewType?: string
  minWidth?: ThemeUICSSObject
}

const ProductCard: React.FC<Props> = ({
  product,
  sales = [],
  maxWidth = '438px',
  maxHeight = '550px',
  viewType = 'grid',
  minWidth = {},
}) => {
  const translate = useTranslate()
  const { name, bastaId, isActive: _isActive, variantsCollection } = product
  const { items: variants } = variantsCollection
  const { price, sku } = variants[0]

  const activeSale = sales.filter(
    (sale) => sale.items.filter((item) => item.id === bastaId).length > 0
  )
  const isActive =
    _isActive === undefined || _isActive === null || _isActive ? true : false
  const [bastaItem, setBastaItem] = useState<{
    sku: string
    endDate: Date
    active: boolean
    latestBid: number
  }>()

  // each product have at least one variant (enforced by Contentful model)
  const initProduct = (activeSale: ISales) => {
    const { dates, items } = activeSale
    const endDate = new Date(dates.closingDate)
    const { currentBid, startingBid } =
      items.filter((_item) => _item.id === bastaId)[0] || null

    setBastaItem({
      sku,
      endDate: endDate,
      active: true,
      latestBid: (currentBid ?? startingBid) / 100,
    })
  }

  useEffect(() => {
    if (!bastaItem && activeSale.length > 0) {
      initProduct(activeSale[0] || null)
    }
  })

  return (
    <Flex
      className={[
        styles.productCard,
        bastaItem?.active ? styles.activeAuction : '',
      ].join('')}
      sx={{
        maxWidth,
        ...{minWidth: minWidth.minWidth},
      }}
    >
      <ProductLink product={product} sx={{ width: '100%' }}>
        <InternalProductCard
          maxHeight={maxHeight}
          product={product}
          price={price}
          basta={bastaItem}
          viewType={viewType}
        />

        {viewType !== 'index' && (
          <>
            <Flex className={styles.productName} sx={{ justifyContent: ['center', null, 'flex-start'] }}>{name}</Flex>
            <Flex className={styles.placeButton} sx={{ justifyContent: ['center', null, 'flex-start'] }}>
              {(bastaItem?.active && isActive) && (<span>{translate('product.place_a_bid')}</span>)}
              {(!bastaItem?.active && isActive) && (<span>{translate('product.buy_now')}</span>)}
              {(!isActive) && (
                <span
                  sx={{
                    color: `${theme.colors.red} !important`,
                    textDecorationColor: `${theme.colors.red} !important`,
                  }}
                >
                  {translate('product.sold_out')}
                </span>
              )}
            </Flex>
          </>
        )}
      </ProductLink>
    </Flex>
  )
}

export default ProductCard
